<template>
  <div class='rescue'>
    <detail-banner :bgUrl="'riskAssess'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="rescue-serve">
        <h3>风险评估的必要性</h3>
        <ul>
            <li>
                <img src="../../assets/images/cloud/icon_fengxian.png" alt="">
                <dd>精准识别风险</dd>
                <dt>通过标准化的风险评估流程，评估风险发生几率大小，分析风险发生后可能带来的影响，帮助客户准确了解风险带来的威胁范围。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_baozheng.png" alt="">
                <dd>分析风险及修复方案</dd>
                <dt>通过风险评估结果表，分析风险对安全的不同影响并正确反馈，制定风险修复及防御实施的可行性报告。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_duice.png" alt="">
                <dd>解决风险措施</dd>
                <dt>了解风险并根据修复方案对潜在风险进行修复，出具合理风险应对措施，高效解决风险。</dt>
            </li>

        </ul>
    </div>

    <div class="rescue-method">
        <div class="rescue-method-serve">
            <h3>服务内容</h3>
            <ul class="list">
                <li>
                    <div class="list-box">
                        <h4>第一步：评估准备</h4>
                        <div>
                            <h5><i class="round"></i> 专家团队、工具包、流程；</h5>
                            <p><i class="round"></i> 制定风险评估方案；</p>
                            <p><i class="round"></i> 对目标应用系统、服务器、数据库、网络环境等进行了解。</p>
                        </div>
                    </div>
                    <img v-lazy="require('../../assets/images/cloud/img_no1.png')" alt="">
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_no2.png')" alt="">
                    <div class="list-box">
                        <h4>第二步：技术评估</h4>
                        <div>
                            <h5><i class="round"></i> 基线评估：对主机、网络设备、数据库、中间件(账户安全、访问控制、网络安全等)；</h5>
                            <p><i class="round"></i> 应用评估：安全功能、日常维护(身份认证、访问权限控制、传输安全等)；</p>
                            <p><i class="round"></i> 渗透测试：业务系统、APP程序、微信小程序(信息泄露、注入漏洞、逻辑错误等)。</p>
                        </div>
                    </div>
                    
                </li>
                <li>
                    <div class="list-box">
                        <h4>第三步：管理评估</h4>
                        <div>
                            <h5><i class="round"></i> 技术管理评估：物理环境、通信与操作管理、访问控制、系统开发与维护、业务连续性；</h5>
                            <p><i class="round"></i> 组织管理评估：安全策略、组织安全、资产分类与控制、人员安全、符合性。</p>
                        </div>
                    </div>
                    <img v-lazy="require('../../assets/images/cloud/img_no3.png')" alt="">
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_no4.png')" alt="">
                    <div class="list-box">
                        <h4>第四步：评估报告</h4>
                        <div>
                            <h5><i class="round"></i> 整理评估中发现的漏洞及风险；</h5>
                            <p><i class="round"></i> 解读风险现状及评估分析结果；</p>
                            <p><i class="round"></i> 提出风险控制方案，为之后的加固整改提出合理化建议。</p>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
    </div>
    <more-prod></more-prod>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '风险评估',
        tips: '从风险管理的角度，为客户分析信息系统中存在的安全威胁和漏洞，发现正在进行中的恶意攻击，完成合规任务，管理信息系统风险。'
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.rescue{
    width: 100%;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 488px;
        text-align: center;
        >h3{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            margin-top: 50px;
        }
        >ul{
            display: flex;
            justify-content: space-between;
            margin-top: 94px;
            >li{
                width: 303px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                >img{
                    width: 90px;
                    height: 90px;
                    object-fit: contain;
                }
                dd{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin: 24px 0 14px 0;
                }
            }
        }
    }
    &-method{
        background: #F7F8FB;
        overflow: hidden;
        &-serve{
            width: 1200px;
            margin: 0 auto;
            
            >h3{
                height: 42px;
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 42px;
                margin-top: 50px;
                text-align: center;
            }
            .list{
                margin-top: 55px;
                >li{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                    display: flex;
                    align-items: center;
                    >img{
                        width: 300px;
                        height: 300px;
                        object-fit: contain;
                        // margin-left: 218px;
                    }
                    
                }
                >li:nth-child(odd){
                    img{
                        margin-left: 218px;
                    }
                }
                >li:nth-child(even){
                    img{
                        margin-left: 114px;
                        margin-right: 188px;
                    }
                    
                }
                &-box{
                    width:580px;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin: 24px 0 14px 0;
                    >div{
                        margin-top: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 32px;
                        .round{
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: #333333;
                        }
                    }
                }
                
            }
        }
    }
}
</style>