<template>
  <footer class="footer">
    <div class="footer-box">
      <div class="top">
        <div class="left">
          <div class="left-box">
            <p>联系我们</p>
            <p>199-5017-6720
              <!-- <span>（何女士）</span> -->
            </p>
            <p>地址：成都市高新区绿地之窗2栋1单元22层01号</p>
            <p>邮箱：dongzxa@dzxinanse.com</p>
            <img v-lazy="require('../assets/images/home/img_erweima.png')" alt="">
          </div>
          
          <div class="tit">
            <p>云安全</p>
            <ul>
              <li @click="jumpHandle('cdn')" >安全CDN防护系统</li>
              <li @click="jumpHandle('clean')" >内容安全监测系统</li>
            </ul>
          </div>
          <div class="tit">
            <p>安全服务</p>
            <ul>
              <li @click="jumpHandle('infiltrate')">渗透测试</li>
              <li @click="jumpHandle('riskAssess')">风险评估</li>
              <li @click="jumpHandle('rescue')">黑客入侵救援</li>
              <li @click="jumpHandle('inforDrill')">信息安全对抗演习</li>
              <li @click="jumpHandle('train')">信息安全意识培训</li>
            </ul>
          </div>
          <div class="tit">
            <p>关于东智</p>
            <ul>
              <li @click="jumpHandle('company')">公司介绍</li>
              <li @click="jumpHandle('join')">加入我们</li>
            </ul>
          </div>
        </div>
        
        <img class="top-img" v-lazy="require('../assets/images/home/img_dibu_logo.png')" alt="">
      </div>
  
      <!-- <div class="link">
        <span>友情链接：</span>
        <span>百度一下</span>
        <span>百度一下</span>
      </div> -->
      <el-divider></el-divider>
      
      <p class="makeARecord"
        >Copyright&nbsp;©&nbsp;成都东智信安信息技术有限公司&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp; <span class="beian" @click="openhandle" >蜀ICP备2022020739号</span></p
      >
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  methods: {
    jumpHandle(path){
      this.$router.push('/'+path)
    },
    openhandle(){
      window.open('https://beian.miit.gov.cn')
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.footer{
  background:#292b32;
  overflow: hidden;
}
.footer-box{
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  color: #ffffff;
  & .top{
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      justify-content: space-between;
      &-box{
        // margin-right: 90px;
        p:first-child{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          margin-bottom: 10px;
        }
        p:nth-child(2){
            font-size: 18px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 21px;
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
          }
          margin-bottom: 15px;
        }
        p:nth-child(3){
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A6A9B2;
          line-height: 17px;
          margin-bottom: 10px;
        }
        p:nth-child(4){
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A6A9B2;
          line-height: 17px;
          margin-bottom: 15px;
        }
        img{
          width: 72px;
          height: 72px;
          object-fit: contain;
          border: 1px solid;
        }
      }
      .tit{
        margin-left: 113px;
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
        }
        ul li{
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A6A9B2;
          line-height: 17px;
          margin-top: 15px;
          cursor: pointer;
          
        }
        ul li:hover{
          color: #fff;
        }
      }
      .right{
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          margin-bottom: 10px;
        }
        span{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A6A9B2;
          line-height: 17px;
          cursor: pointer;
        }
      }
    }
    &-img{
      width: 107px;
      height: 107px;
      object-fit: contain;
    }
    

    
  }

  & .makeARecord{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A6A9B2;
    line-height: 17px;
    margin:  20px 0;
  }
  .beian{
    color: #326FFA;
    text-decoration: none;
    cursor: pointer;
  }

  .link{
    margin-top: 40px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A6A9B2;
    span{
      margin-right: 20px;
      cursor: pointer;
    }
  }
  
}

/deep/ .el-divider--horizontal{
  background:#37393f;
  margin: 20px 0 20px 0;
}
</style>