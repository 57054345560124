<template>
  <div class='banner'  :style="{backgroundImage: 'url('+ bgImgObj[bgUrl] +')'}">
    <div class="banner-box">
        <h3 class="h3" >{{tit}}</h3>
        <p class="p">{{tips}}</p>
        <el-button class="btn" type="primary" @click="submitHandle" >立即咨询</el-button>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        bgUrl:{
            type: String
        },
        tit: {
            type: String
        },
        tips: {
            type: String
        }
    },
  components: {},
  data() {
    return {
        bgImgObj:{
            shentou: require('../assets/images/cloud/img_banner_shentou.png'),
            heike: require('../assets/images/cloud/img_banner_heike.png'),
            riskAssess: require('../assets/images/cloud/img_banner_fengxianpinggu.png'),
            inforDrill:require('../assets/images/cloud/img_banner_duikang.png'),
            train:require('../assets/images/cloud/img_banner_xxanquanpeix.png'),
            cdn:require('../assets/images/cloud/img_banner_cdn.png'),
            DClear:require('../assets/images/cloud/DClean/img_banner_dwangjing.png'),
            heike:require('../assets/images/cloud/speedServe/img_banner_heike.png'),
            
        }
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitHandle(){
        this.$store.commit('dialogHandle',true)
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.banner{
    height: 390px;
    width: 100%;
    border: 1px solid;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    &-box{
        width: 1200px;
        margin: 0 auto;
        margin-top: 98px;
        .h3{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 56px;
        }
        .p{
            width: 550px;
            height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            margin-top: 16px;
            margin-bottom: 40px;
        }
        .btn{
            background-color: #2149DD;
            border: #2149DD;
        }

    }

}
</style>