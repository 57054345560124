<template>
  <div class="home">
    <div class="home-carousel">
      <el-carousel height="460px">
        <el-carousel-item :style="{backgroundImage: 'url('+[Image[idx]]+')'}" class="carouselItem" v-for="(item,idx) in 1" :key="item">
          <div class="infor">
            <h5>域名防护上线</h5>
            <span class="info3">提升域名对威胁的防控能力，切实保障域名平稳高效安全运行</span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="mod3 flex-col">
        <div class="TextGroup1 flex-col justify-between">
          <span class="txt4">东智产品</span>
          <span class="word2">一站式安全防护，保障业务安全</span>
        </div>
    </div>
    <div class="mod4 flex-col">
      <div class="mod4-tabs">
        <div class="tab">
          <span @click="tabFlag = true" :class=" tabFlag? 'activeName' : '' ">云安全产品</span>
          <span @click="tabFlag = !tabFlag " :class=" !tabFlag? 'activeName' : ''"  >安全服务</span>
        </div>
        <div v-show="tabFlag" class="tab-content">
          <!-- <ul> -->
          <XyzTransitionGroup
            class="tab-content-ul"
            appear-visible
            xyz="fade small out-down out-rotate-right appear-stagger"
          >
            <div class="tab-content-ul-li" key="1">
              <img src="../assets/images//home/icon_ product1.png" alt="">
              <dl>
                <dt>安全CDN防护系统</dt>
                <dd>基于域名管理、资源管理、功能配置三大功能模块，对成功接入系统的域名设置多维度的防护配置，一整套完整的功能，可对用户网站做到精确防护，保障网站安全，为业务保驾护航。</dd>
                <el-button @click="$router.push('/cdn')">了解详情</el-button>
              </dl>
            </div>
            <div class="tab-content-ul-li" key="2">
              <img src="../assets/images//home/product2.png" alt="">
              <dl>
                <dt>内容安全监测系统</dt>
                <dd>配备各大词库数据库，提供更全面的词库防护，精准识别文字，多策略保护网站内容，可做到拦截、检测、自动替换文字，高效助力网络净化。</dd>
                <el-button @click="$router.push('/clean')" >了解详情</el-button>
              </dl>
            </div>
          </XyzTransitionGroup>
          <!-- </ul> -->
        </div>
        <div v-show="!tabFlag" class="tab-serve">
          <!-- <div class="left">
            <img src="../assets/images/home/icon_serve1.png" alt="">
            <dl>
              <dt>风险评估</dt>
              <dd>帮助企业系统分析资产所面临的威胁，及其存在的脆弱性，评估安全事件一旦发生可能造成的危害程度。并提出有针对性的抵御威胁的防护对策、整改措施。为防范和化解风险提供科学依据。</dd>
            </dl>
            <el-button @click="$router.push('/riskAssess')" >了解详情</el-button>
          </div> -->
          <ul>
            <li @click="$router.push('/infiltrate')"  >
              <img src="../assets/images/home/icon_serve1.png" alt="">
              <dl>
                <dt>风险评估</dt>
                <dd>帮助企业系统分析资产所面临的威胁，及其存在的脆弱性，评估安全事件一旦发生可能造成的危害程度。并提出有针对性的抵御威胁的防护对策、整改措施。为防范和化解风险提供科学依据。</dd>
              </dl>
            </li>
            <li @click="$router.push('/infiltrate')"  >
              <img src="../assets/images/home/icon_serve2.png" alt="">
              <dl>
                <dt>渗透测试</dt>
                <dd>模拟黑客攻击对业务系统进行安全性测试，比黑客更早发现可导致企业数据泄露、资产受损、数据被篡改等漏洞，并协助企业进行修复。</dd>
              </dl>
            </li>
            <li @click="$router.push('/rescue')" >
              <img src="../assets/images/home/icon_serve3.png" alt="">
              <dl>
                <dt>黑客入侵救援</dt>
                <dd>快速解决服务器被黑客控制、服务器被种后门、木马、病毒等严重的黑客入侵事件。</dd>
              </dl>
            </li>
            <li @click="$router.push('/vulService')" >
              <img src="../assets/images/home/icon_serve6.png" alt="">
              <dl>
                <dt>漏洞速递服务</dt>
                <dd>对国内主要漏洞发布平台进行监控，发现正对客户业务应用系统的漏洞信息，在第一时间通知客户，进行预警。</dd>
              </dl>
            </li>
            <li @click="$router.push('/inforDrill')">
              <img src="../assets/images/home/icon_serve4.png" alt="">
              <dl>
                <dt>信息安全对抗演习</dt>
                <dd>针对提高企业员工安全意识和安全团队技术能力，促进政府、央企及企业的信息安全团队的交流、学习，发现系统薄弱点。</dd>
              </dl>
            </li>
            <li @click="$router.push('/train')">
              <img src="../assets/images/home/icon_serve5.png" alt="">
              <dl>
                <dt>信息安全意识培训</dt>
                <dd>帮助企业了解员工的安全知识掌握程度，并通过培训与演练，提升员工网络安全知识，减少员工在日常工作中由于安全知识的匮乏使企业造成重大损失的几率。</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="TextGroup5">
      <div class="">
        <span class="word5">我们的优势</span>
        <span class="word6">针对网站防护，提供专业工具，保护业务安全</span>
      </div>
      <!-- <div class="flex-row " > -->
      <XyzTransitionGroup
        appear-visible
        class="flex-row"
        xyz="fade small out-down out-rotate-right appear-stagger"
      >
        <div class="flex-col" key="1" xyz="inherit left" >
          <img src="../assets/images/home/performance.png" alt="" srcset="">
          <div class="section ">
            <h4 class="txt6">高性能  低误报</h4>
            <span class="info5"
              >自定义配置WAF防护，可只对有效攻击进行拦击，降低误报，不影响正常用户访问。</span
            >
          </div>
        </div>
        <div class="flex-col" key="2" xyz="inherit up" >
          <img src="../assets/images/home/icon_rizhifenxi.png" alt="" srcset="">
          <div class="section ">
            <h4 class="word7">安全稳定</h4>
            <span class="word8"
              >智能调度机制，风控体系可自动隔离风险用户，免疫CC攻击。</span
            >
          </div>
        </div>
        <div class="flex-col" key="3" >
          <img src="../assets/images/home/limit.png" alt="" srcset="">
          <div class="section ">
            <h4 class="word9">多维度访问限制</h4>
            <span class="txt7"
              >地域访问限制、自定义访问控制、IP防护等，多维度的访问防护规则，满足业务的定制防护需求。</span
            >
          </div>
        </div>
        <div class="flex-col" key="4" >
          <img src="../assets/images/home/icon_yuanzhanbaohu.png" alt="" srcset="">
          <div class="section ">
            <h4 class="info6">7*24专家服务</h4>
            <span class="info7"
              >安全运维专家团队 7x24 小时全天候监控，确保 Web 业务始终保持高效流畅的体验。</span
            >
          </div>
        </div>
        </XyzTransitionGroup>
      <!-- </div> -->
        
    </div>
    <div class="case" v-if="false">
      <div class="top">
        <h4 >客户案例</h4>
        <span>客户的口碑就是我们赖以生存的生命线</span>
      </div>
      <div class="content">
        <el-carousel indicator-position="outside" arrow="never" height="246px">
          <el-carousel-item v-for="item in 1" :key="item">
            <div class="content-box">
              <div class="left">
                <img src="../assets/images/home/img_logo.png" alt="">
              </div>
              <div class="right">
                <h4 class="">对东智的评价：</h4>
                <span class="">东智非常的专业，为我们定制的防护系统满足我们的需求，以前我们的网站经常被攻击，导致公司服务器经常崩溃，现在有了安全CDN防护系统对我们帮助非常大，公司的业务数据也得到了更好的保障。现在我们与东智不仅仅是合作关系，而更像是朋友，再次我再次感谢东智，也祝东智越做越好！</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      
      <!-- <div class="content">
        <div class="content-box">
          <div class="left">
            <img src="../assets/images/home/img_logo.png" alt="">
          </div>
          <div class="right">
            <h4 class="">对东智的评价：</h4>
            <span class="">东智非常的专业，为我们定制的防护系统满足我们的需求，以前我们的网站经常被攻击，导致公司服务器经常崩溃，现在有了安全CDN防护系统对我们帮助非常大，公司的业务数据也得到了更好的保障。现在我们与东智不仅仅是合作关系，而更像是朋友，再次我再次感谢东智，也祝东智越做越好！</span>
          </div>
        </div>
      </div> -->
      <div class="outer5 flex-row justify-between">
        <div class="main9 flex-col"></div>
        <div class="main10 flex-col"></div>
        <div class="main11 flex-col"></div>
        <div class="main12 flex-col"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  metaInfo: {
        title: "东智信安，一家提供网络安全产品及应用交付综合服务的网络安全公司",
        meta: [
            {
                name: "keyWords",
                content: "云安全，云防御，web安全，WAF防护，网站防护，CC，CDN，渗透测试，内容检测，漏洞威胁，安全防护",
            },
            {
                name: "description",
                content:
                    "成都东智信安信息技术有限公司致力于成为一家具有优秀企业文化和社会责任感的新时代网络信息安全产品和服务提供商。",
            },
        ],
  },
  data(){
    return {
      tabFlag:true,
      // dialogFormVisible:false,
      Image:[require('../assets/images/home/home_img_banner.png'),require('../assets/images/home/home_img_banner.png')],
      loopData0: [
        {
          lanhuBg2Far:require('../assets/images/home/home_icon_yumingguanli_bg.png'),
          lanhuBg2:require('../assets/images/home/home_icon_yumingguanli_n.png'),
          lanhuBg2Hover:require('../assets/images/home/home_icon_yumingguanli.png'),
          lanhutext0: 'CC防护引擎',
          lanhutext1: '基于CC人机识别、CC基础引擎两大模块配置，针对受到CC攻击时，利用流量攻击清洗机制，进行高效防御。系统实时监控，主动防御并生成攻击日志，统计攻击来源与攻击目标。'
        },
        {
          lanhuBg2Far:require('../assets/images/home/home_icon_ziyuanguanli_bg.png'),
          lanhuBg2: require('../assets/images/home/home_icon_ziyuanguanli.png'),
          lanhutext0: '智盾神（Web应用防火墙）',
          lanhutext1:'基于自定义规则引擎可以灵活定制防护，可生成数百种策略级纬度的访问控制防护规则，满足业务的定制防护需求。实时保护Web应用免遭网络、应用、业务等各种已知和未知威胁。'
        },
        {
          lanhuBg2Far:require('../assets/images/home/home_icon_gongnengpeizhi_bg.png'),
          lanhuBg2: require('../assets/images/home/home_icon_gongnengpeizhi.png'),
          lanhutext0: 'DNS智能解析调度',
          lanhutext1: '安全可靠的智能DNS解析服务，领先的高性能架构，拥有安全防御能力、海量请求处理能力，支持全球及自定义智能解析线路，提供安全、稳定、可靠的解析体验。'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100%;

  &-carousel{
    width: 100%;
    .carouselItem{
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .infor{
      width: 1200px;
      margin:  0 auto;
      margin-top: 168px;
      
      h5{
        height: 56px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0C0C0C;
        line-height: 56px;
      }
      span{
        display: block;
        width: 432px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0C0C0C;
        line-height: 22px;
        padding-top: 14px;
        
      }
    }
  }

  .mod3 {
      height: 179px;
      width: 100%;
      overflow: hidden;
      position: relative;
      .TextGroup1 {
        height: 79px;
        width: 540px;
        margin: 0 auto;
        margin-top: 50px;
        .txt4 {
          display: inline-block;
          width: 100%;
          text-align: center;
          height: 42px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 42px;
        }
        .word2 {
          display: inline-block;
          width: 100%;
          text-align: center;
          height: 21px;
          overflow-wrap: break-word;
          color: rgba(155, 158, 165, 1);
          font-size: 15px;
          white-space: nowrap;
          line-height: 21px;
          margin-top: 16px;
          
        }
      }
  }

  .mod3::after{
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left:0;
    right: 0;
    margin:0 auto;
    bottom: 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 15px solid #f6f7fa;
  }

  .mod4 {
    width: 100%;
    height: 870px;
    background-color: #f7f8fb;
    margin-top: -12px;
    width: 100%;
    overflow: hidden;
    &-tabs{
      width: 1200px;
      margin:  0 auto;
      margin-top: 50px;
      .tab{
        width: 460px;
        margin:  0 auto;
        display: flex;
        justify-content: space-between;
        span{
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 33px;
          cursor: pointer;
        }
        .activeName{
          font-weight: 600;
          color: #333333;
          position: relative;
          
        }
        .activeName::after{
          content: '';
          width: 100%;
          position: absolute;
          height: 3px;
          background: #2149DD;
          bottom: -14px;
          left: 0;
        }
        &-content{
          width: 1200px;
          height: 670px;
          background: #FFFFFF;
          border-radius: 8px;
          border: 1px solid #F1F1F1;
          margin-top: 30px;
          box-sizing: border-box;
          padding: 40px;
          &-ul{
            &-li{
              height: 279px;
              background: #F7F8FB;
              border-radius: 6px;
              box-sizing: border-box;
              padding: 40px 30px ;
              display: flex;
              img{
                width: 46px;
                height: 46px;
                margin-right: 20px;
              }
              dl{
                margin-top: 12px;
              }
              dt{
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 25px;
              }
              dd{
                margin-top: 20px;
                margin-bottom: 40px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
              }
            }
            &-li:last-child{
              margin-top: 30px;
            }
          }
        }
        &-serve{
          margin-top: 30px;
          width: 1200px;
          height: 670px;
          background: #FFFFFF;
          border-radius: 8px;
          border: 1px solid #F1F1F1;
          background-color: #fff;
          box-sizing: border-box;
          padding: 40px;
          dl{
            cursor: pointer;
            dt{
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 25px;
              margin-top: 12px;
              position: relative;
              display:inline-block;
            }
            dt::after{
              content: '';
              position: absolute;
              left: 0;
              bottom: -20px;
              // width: 74px;
              width: 100%;
              height: 2px;
              background: #2149DD;
            }
            dd{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              margin-top: 40px;
              margin-bottom: 60px;
            }
          }
          // .left{
          //   width: 356px;
          //   height: 588px;
          //   border-radius: 6px;
          //   background: #F7F8FB;
          //   box-sizing: border-box;
          //   padding: 30px;
          //   img{
          //     width: 46px;
          //     height: 46px;
          //   }

          // }
          ul{
            // height: 588px;
            display: flex;
            justify-content: space-between;
            align-content: space-between;
            flex-wrap: wrap;
            li{
              width: 350px;
              height: 279px;
              background: #F7F8FB;
              border-radius: 6px;
              box-sizing: border-box;
              padding: 30px;
              margin-bottom: 30px;
            }
          }

        }
      }
    }
    
  }

  .TextGroup5 {
    
      height: 553px;
      width: 100%;
      .flex-row{
        width: 1200px;
        margin: 0 auto;
        margin-top: 105px;
        display: flex;
        justify-content: space-between;
        .flex-col{
          width: 278px;
          height: 236px;
          background: #FFFFFF;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          position: relative;
          // border: 1px solid red;
          img{
              width: 82px;
              height: 82px;
              // object-fit: contain;
              position: absolute;
              left: 50%;
              bottom: 50%;
              transform: translate(-50%,-100%);
          }
          .section{
            text-align: center;
            width: 230px;
            margin: 0 auto;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            
            
          }
          h4{
            width: 100%;
            margin-top: 55px;
            text-align: center;
            height: 25px;
            color: rgba(0, 0, 0, 1);
            font-size: 18px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            white-space: nowrap;
            line-height: 25px;
            display: block;


          }
          span{

            width: 230px;
            height: 52px;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 14px;
            text-align: justify;
            line-height: 26px;
            margin-top: 21px;
            // overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          
          }
        }
        
      }
      // margin: 50px 0 0 810px;
      .word5 {
        margin-top: 50px;
        text-align: center;
        height: 42px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 42px;
        display: block;
      }
      .word6 {
        text-align: center;
        height: 21px;
        overflow-wrap: break-word;
        color: rgba(155, 158, 165, 1);
        font-size: 15px;
        white-space: nowrap;
        line-height: 21px;
        margin-top: 16px;
        display: block;
      }
  }
  .case{
    border: 1px solid;
    height: 568px;
    background:url('../assets/images/home/img_case_bj.png');
    background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    .top{
      text-align: center;
      color: #FFFFFF;
      h4{
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: 40px;
      }
      span{
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 21px;
        
      }
    }
    .content{
      width: 1200px;
      height: 246px;
      margin: 0 auto;
      margin-top: 70px;
      background: #FFFFFF;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 50px 58px ;
      &-box{
        display: flex;
        .left{
          margin-right: 40px;
          img{
            width: 190px;
            height: 136px;
            object-fit: contain;
          }
        }
        
        .right{
          flex: 1;
          h4{
            height: 30px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 30px;
            margin-bottom: 16px;
          }
          span{
            height: 90px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
          }
        }
      }
    }
  }
  
}
</style>