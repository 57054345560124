<template>
  <header class='header' >
    <div class="box1 flex-col">
        <div class="box1-logo">
          <img src="../assets/images/home/img_logo.png" alt="" @click="$router.push('/')" >
        </div>
        <nav class="nav">
          <span  @click="jumpHandle('')" @mouseover="hoverHandle">首页</span>
          <span @mouseover="hoverHandle('prod')" >
            产品
            <img v-show="!prodHover" src="../assets/images/home/icon_xiala_n.png" alt="">
            <img v-show="prodHover" src="../assets/images/home/icon_xiala_h.png" alt="">
          </span>
          <span
            @mouseover="hoverHandle('firm')"
          >
            关于东智
            <img v-show="!firmHover" src="../assets/images/home/icon_xiala_n.png" alt="">
            <img v-show="firmHover" src="../assets/images/home/icon_xiala_h.png" alt="">
          </span>
        </nav>
        <div class="wrap3 flex-col">
          <div class="main1 flex-col justify-between">
            <span class="info2">咨询电话</span>
            <span class="txt2">199-5017-6720</span>
          </div>
        </div>
    </div>
    <div class="modal" v-show="modal" @mouseleave="modelLeaveHandle" >
      <div class="modal-box">
        <div class="modal-box-dl" v-show="whoHover == 'prod' ">
            <dl>
              <dt>云安全</dt>
              <dd @click="jumpHandle('cdn')">安全CDN防护系统</dd>
              <!-- <span class="clean">(内容监测管理)</span> -->
              <dd @click="jumpHandle('clean')">内容安全监测系统</dd>
            </dl>
            <dl>
              <dt>安全服务</dt>
              <dd @click="jumpHandle('infiltrate')">渗透测试</dd>
              <dd @click="jumpHandle('riskAssess')">风险评估</dd>
              <dd @click="jumpHandle('rescue')">黑客入侵救援</dd>
              <dd @click="jumpHandle('inforDrill')">信息安全对抗演习</dd>
              <dd @click="jumpHandle('train')">信息安全意识培训</dd>
              <dd @click="jumpHandle('vulService')">漏洞速递服务</dd>

            </dl>
        </div>
        <ul class="modal-box-firm" v-show="whoHover == 'firm' " >
            <li @click="jumpHandle('company')">公司介绍</li>
            <li @click="jumpHandle('join')">加入我们</li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      prodHover: false,
      firmHover:false,
      modal:false,
      whoHover: 'prod'
    };
  },
  computed: {},
  watch: {},
  methods: {
    hoverHandle(v){
      this.modal = false
      this.firmHover = false
      this.prodHover = false
      if(v== 'prod'){
        this.modal=true
        this.whoHover = 'prod'
        this.prodHover =true
      }

      if(v== 'firm'){
        this.modal=true
        this.whoHover = 'firm'
        this.firmHover =true
      }
    },
    modelLeaveHandle(v){
      this.modal = false
      this.firmHover = false
      this.prodHover = false
    },
    jumpHandle(path){
      this.$router.push('/'+path)
      this.modal = false
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.header{
  width: 100%;
  align-items: center;
  height: 70px;
  background: #FFFFFF;
  // box-shadow: 0px 2px 6px 0px rgba(61, 62, 65, 0.09);
  backdrop-filter: blur(4px);
  position: relative;
  .box1 {
    height: 100%;
    display: flex;
    align-items: center;
    .nav{
      font-weight: 500;
      span{
        font-size: 16px;
      }
      span:hover{
        color: #426ef1;
      }
      img{
        width: 8px;
        height: 8px;
        object-fit: contain;
        margin-top: -4px;
        margin-left: 5px;
      }
      
    }
    &-logo{
      width: 126px;
      height: 36px;
      margin-left: 30px;
      img{
        object-fit: contain;
        cursor: pointer;
      }
    }
    // .box5 {
    //   width: 126px;
    //   height: 32px;
    //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng05a3c47ae435ad9d19d2b82e4c970174ff5df94825c09b035a111cc53a8d8994)
    //     0px 0px no-repeat;
    // }
    .nav{
      flex: 1;
      margin-left: 102px;
      span{
        margin-right: 50px;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    

  .wrap3 {
      background: #2149DD;
      background-color: rgba(33, 73, 221, 1);
      height: 100%;
      display: flex;
      align-items: center;
      .main1 {
        min-width: 126px;
        padding: 10px;
        .info2 {
          // width: 56px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(250, 251, 252, 1);
          font-size: 14px;
          text-align: justify;
          white-space: nowrap;
          line-height: 20px;
          display: block;
          
        }
        .txt2 {
          height: 26px;
          overflow-wrap: break-word;
          color: rgba(250, 251, 252, 1);
          font-size: 20px;
          font-family: DINPro-Bold;
          text-align: justify;
          white-space: nowrap;
          line-height: 26px;
          display: block;
        }
      }
    }
    
  }
  .modal{
    position:absolute;
    width: 100%;
    padding-bottom: 30px;
    // min-height: 310px;
    top: 70px;
    background-color:#FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(61,62,65,0.0900);
    transition: top 500ms;
    z-index: 100000;
    border-top: 1px solid #F7F8FB;
    &-box{
      // width: 1200px;
      // margin: 0 auto;
      margin-left: 260px;
      
      &-dl{
        margin-top: 40px;
        // margin-left: 20px;
        display: flex;
        // margin-left: 110px;
        dt{
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2149DD;
            line-height: 22px;
            margin-bottom: 40px;
            position: relative;
        }
        dt::after{
          display: inline-block;
          width: 100%;
          position: absolute;
          content: '';
          height: 1px;
          background: #F0F1F3;
          bottom: -10px;
          left: 0;

        }
        dd{
          margin-bottom: 14px;
          cursor: pointer;
          line-height: 20px;
        }
        dd:hover{
          color: #2149DD;
        }
        
        dl:last-child{
          margin-left: 88px;
          
        }
      }
      &-firm{
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-top: 30px;
        margin-left: 190px;
        li{
          margin-right: 20px;
        }
        li:hover{
          color: #2149DD;
          cursor: pointer;
        }
      }
      
    }
      
  }

}


</style>