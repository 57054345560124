<template>
  <div class='train'>
    <detail-banner :bgUrl="'train'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="train-serve">
        <div class="importance">
            <img src="../../assets/images/cloud/img_xinxianquan.png" alt="">
            <dl>
                <dt>信息安全意识培训的重要性</dt>
                <dd>信息安全意识的薄弱正在成为企业面临的最大风险，忽视信息安全意识培训教育，可能遭受灾难性的打击。员工只有有了信息安全意识，才会有好的信息安全行为，才能有效保障企业和组织的安全，所以开展信息安全意识培训对于企业至关重要。</dd>
            </dl>
        </div>
    </div>

    <div class="train-my">
        <div class="policy">
            <img src="../../assets/images/cloud/img_zhengce.png" alt="">
            <dl>
                <dt>政策及背景</dt>
                <dd>2017年6月1日我国施行了《中华人民共和国网络安全法》，其中第十九条明确规定：各级人民政府及其有关部门应当组织开展经常性的网络安全宣传教育，并指导、督促有关单位做好网络安全宣传教育工作。对公共通信和信息服务、能源、交通、水利、金融、公共服务、电子政务等重要行业和领域提出信息安全意识教育相关要求。</dd>
            </dl>
        </div>
        <div class="myServe">
            <h3>我们的服务</h3>
            <ul>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_ serve1.png')" alt="">
                    <h5>员工安全意识评估</h5>
                    <p>Safety awareness assessment</p>
                    <p>通过题目测试、安全调查问卷、员工回答、工具实施检测等方式检查企业员工的安全意识短板，评估培训策略，定制针对性培训计划。</p>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_ serve2.png')" alt="">
                    <h5>现场培训</h5>
                    <p>TRAIN</p>
                    <p>一流技术安全专家为企业员工进行网络安全意识培训，通过课堂演示、案例剖析、攻防模拟演练等多维度的授课方式将安全知识有效传递给企业员工。
                        培训结束后，通过考试及模拟多种网络攻击方式，对企业员工进行网络安全意识测评，根据员工测评结果，输出员工对此次培训掌握情况及实际应用情况。
                    </p>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_ serve3.png')" alt="">
                    <h5>员工安全意识评估</h5>
                    <p>safety propaganda</p>
                    <p>根据企业员工意识考核结果，为企业定制安全意识宣传册、宣传海报等，加强企业宣传安全知识。</p>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/img_ serve4.png')" alt="">
                    <h5>员工安全意识评估</h5>
                    <p>regression testing</p>
                    <p>培训结束后2-3个月内，东智信安白帽子黑客将针对员工安全意识突击检查，检查企业员工安全自律性、习惯、制度落实情况等。保障企业落实安全意识的管理实施。</p>
                </li>
            </ul>
        </div>
    </div>
    <more-prod></more-prod>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '信息安全意识培训',
        tips: '帮助企业了解员工的安全知识掌握程度，并通过培训与演练，提升员工网络安全知识，减少员工在日常工作中由于安全知识的匮乏使企业造成重大损失的几率。'
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.train{
    width: 100%;
    background-color: #F7F8FB;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 488px;
        
        .importance{
            box-sizing: border-box;
            padding: 67px 124px 0 150px;
            display: flex;
            align-items: center;
            img{
                width: 240px;
                width: 240px;
                object-fit: contain;
            }
            >dl{
                margin-left: 160px;
                dt{
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 33px;
                }
                dd{
                    margin-top: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                }
            }
        }

    }
    &-my{
        height: 830px;
        background: #fff;
        position: relative;
        .policy{
            background-color: #fff;
            width: 1200px;
            height: 340px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 0 83px;
            position: absolute;
            box-shadow: 0px 4px 8px 0px rgba(61,62,65,0.0900);
            border-radius: 6px;
            left: 50%;
            top: -50%;
            transform: translate(-50%,80%);
            img{
                width: 390px !important;
                width: 202px;
                object-fit: contain;
            }
            >dl{
                margin-left: 79px;
                dt{
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 33px;
                }
                dd{
                    margin-top: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                }
            }
        }
        .myServe{
            width: 1200px;
            margin: 0 auto;
            padding-top: 220px;
            
            >h3{
                height: 42px;
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 42px;
                margin-top: 50px;
                text-align: center;
            }
            >ul{
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
                img{
                    width: 277px;
                    height: 120px;
                }
                li{
                    width: 277px;
                }
                li >h5{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin-top: 30px;
                    margin-bottom: 6px;
                }
                p:nth-child(3){
                    font-size: 12px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #1B44E6;
                    line-height: 17px;
                }
                p:last-child{
                    margin-top: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                }
            }
        
        }
    }
}
</style>