<template>
  <div sticky-container >
    <Header v-sticky ></Header>
    <!-- <keep-alive> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
    <Footer></Footer>
    <ConsulDialog></ConsulDialog>
    <div class="fixed">
      <XyzTransition appear xyz="big iterate-infinite duration-10 direction-alternate"  >
        <div  class="fixedBox consult" @click="consultHandle" >
          <img src="../../assets/images/home/icon_consult.png" alt="">
          <span class="btn">咨询</span>
        </div>
      </XyzTransition>
      <transition name="el-zoom-in-top">
        <div v-show="showFlag" class="fixedBox toTop" @click="toTopHandle" >
          <img src="../../assets/images/home/icon_top.png" alt="">
          <span class="btn">置顶</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ConsulDialog from '../../components/consulDialog.vue'
export default {
  components: {
      Footer, Header,ConsulDialog
  },
  data() {
    return {
      showFlag:false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    consultHandle(){
      this.$store.commit('dialogHandle',true)
    },
    toTopHandle () {
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame( function fn() {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          self.timer = requestAnimationFrame(fn)
        } else {
          self.showFlag= false
          cancelAnimationFrame(self.timer)
        }
      })
    },
    handleScroll(){
      const oTop = document.body.scrollTop || document.documentElement.scrollTop
      if(oTop>500){
        this.showFlag= true
      } else{
        this.showFlag= false
      }
    }
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll)
  }
};
</script>

<style lang='scss' scoped>

.xyz-appear {
  --xyz-scale-default: .2;
}
  .fixed{
    position: fixed;
    right: 20px;
    top: 50%;
    z-index: 99;
    .fixedBox{
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      padding: 6px 0;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      .btn{
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        padding-top: 2px;
      }
      img{
        width: 18px;
        height: 18px;
      }

    }
    .consult{
      background: #326FFA;
    }

    .toTop{
      margin-top: 10px;
      background: #F7F8FB;
      .btn{
        color: #8A8A94;
      }
    }
  }
  
</style>