<template>
  <el-dialog class="dialog" top="25vh" width="880px" :close-on-click-modal="false" :show-close="false" :visible.sync="dialogFormVisible">
        <div class="left">
          <img src="../assets/images/home/img_zixun.png" alt="">
        </div>
        <div class="wrap9 flex-col">
          <div class="group6 flex-row justify-between">
            <div class="TextGroup13 flex-col justify-between">
              <span class="word24">立即咨询</span>
              <span class="txt10">Immediately&nbsp;consult</span>
            </div>
            <div class="close" @click="closeHandle" >
              <img src="../assets/images/home/icon_guanbi.png" alt="">
            </div>
          </div>
          <ul class="wrap9-concate">
            <li>
              <img src="../assets/images/home/icon_iphone_home.png" alt="">
              <div>
                <dt>联系电话</dt>
                <dd>199-5017-6720</dd>
              </div>
            </li>
            <li class="email">
              <img src="../assets/images/home/icon_email.png" alt="">
              <div>
                <dt>联系邮箱</dt>
                <dd>dongzxa@dzxinanse.com</dd>
              </div>
            </li>
          </ul>
          <div class="divider">
            <el-divider></el-divider>
          </div>
          <div class="qr">
            <img src="../assets/images/home/img_erweima.png" alt="">
            <dl>
              <dt>或添加微信咨询</dt>
              <dd>扫一扫添加微信</dd>
            </dl>
          </div>
          <!-- <div class="group7 flex-row">
            <div class="box14 flex-col">
              <img src="../assets/images/home/icon_bianji.png" alt="">
              <el-input
                style="width:100%;height:110px"
                type="textarea"
                :rows="2"
                placeholder="请输入您想要咨询的问题"
                v-model="form.textarea"
                class="aaaa"
                maxlength="200"
                show-word-limit
              >
              <i slot="prefix" class="el-input__icon el-icon-date"></i>
              </el-input>
            </div>
          </div>
          <div class="group8 flex-row">
            <div class="box15 flex-col">
              <img src="../assets/images/home/icon_chenghu.png" alt="">
                <el-input
                style="width:100%;height:50px"
                placeholder="请输入您的称呼"
                v-model="form.textarea"
                maxlength="20"
                show-word-limit
              >
              </el-input>
            </div>
          </div>
          <div class="group9 flex-row">
            <div class="group4 flex-col">
              <img src="../assets/images/home/icon_iphone.png" alt="">
              <el-input
                style="width:100%;height:50px"
                placeholder="请输入您的联系方式，邮箱或手机号码"
                v-model="form.textarea"
              >
              </el-input>
            </div>
          </div>
          <div class="group11 flex-row">
            <div class="box17 flex-col"><span class="info12">提交</span></div>
          </div> -->
        </div>
    </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form:{
        textarea: ''
      }
    };
  },
  watch: {},
  methods: {
    closeHandle(){
      this.$store.commit('dialogHandle',false)
    }
  },
  computed:{
    dialogFormVisible(){
      return this.$store.getters.consultDialog
    }
  }
};
</script>

<style lang='scss' scoped>
/deep/ .el-textarea__inner{
  height: calc(100% - 8px);
  margin-top: 7px;
  background: #F5F7FA;
  border: none;
  position: absolute;
  resize:none
}

 /deep/ .el-input__inner{
  margin-top: 5px;
  background: #F5F7FA;
  border: none;
}
/deep/ .el-dialog__body{
  height: 503px;
  padding: 0;
  position: relative;
  .el-input__count{
    background: #f3f7f9;
    .el-input__count-inner{
      background: #f3f7f9;
    }
  }
  
  & .left{
    background:#426ef1;
    position: absolute;
    // border-radius: 18px;
    left: 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 18px;
    }
  }

  .wrap9 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 8px;
      z-index: 212;
      height: 503px;
      width: 480px;
      position: absolute;
      right: 0;
      // left: 920px;
      top: 0px;
      .group6 {
        width: 409px;
        height: 54px;
        margin: 27px 0 0 50px;
        position: relative;
        .TextGroup13 {
          height: 54px;
          width: 169px;
          .word24 {
            width: 80px;
            height: 28px;
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 20px;
            font-family: PingFangSC-Semibold;
            text-align: left;
            white-space: nowrap;
            line-height: 28px;
            display: block;
          }
          .txt10 {
            width: 169px;
            height: 20px;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            text-transform: uppercase;
            font-family: PingFangSC-Semibold;
            text-align: left;
            white-space: nowrap;
            line-height: 20px;
            margin-top: 6px;
            display: block;
          }
        }
        .close {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 12px;
          height: 12px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
         
        }
      }
      .group7 {
        width: 380px;
        height: 110px;
        margin: 22px 0 0 50px;
        .box14 {
          background-color: #F5F7FA;
          border-radius: 4px;
          height: 110px;
          width: 380px;
          display: flex;
          img{
            width: 15px;
            height: 15px;
            margin-left: 14px;
            object-fit: contain;
            margin-top: 15px;
          }
        }
      }
      .group8 {
        width: 380px;
        height: 50px;
        margin: 20px 0 0 50px;
        .box15 {
          background-color: #F5F7FA;
          border-radius: 4px;
          width: 380px;
          display: flex;
          align-items: center;
          img{
            width: 17px;
            height: 17px;
            margin-left: 14px;
            object-fit: contain;
          }
        }
      }
      .group9 {
        width: 380px;
        height: 50px;
        margin: 20px 0 0 50px;
        .group4 {
          background-color: #F5F7FA;
          border-radius: 4px;
          width: 380px;
          display: flex;
          align-items: center;
          img{
            width: 16px;
            height: 16px;
            margin-left: 14px;
            object-fit: contain;
          }
          
        }
      }
      .group11 {
        width: 380px;
        height: 50px;
        margin: 20px 0 80px 50px;
        .box17 {
          background-color: rgba(50, 111, 250, 1);
          border-radius: 4px;
          height: 50px;
          line-height: 50px;
          width: 380px;
          cursor: pointer;
          .info12 {
            width: 100%;
            // height: 20px;
            text-align: center;
            
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            font-family: PingFangSC-Semibold;
            line-height: 50px;
            display: block;
            // margin: 15px 0 0 176px;
          }
        }
      }
      &-concate{
        // margin-left: 50px;
        // margin-top: 40px;
        box-sizing: border-box;
        padding: 40px 40px 20px;
        li{
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          img{
            width: 54px;
            height: 54px;
            margin-right: 20px;
          }
          dt{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          dd{
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 28px;
            margin-top: 4px;
          }
        }
      }
      .divider{
        box-sizing: border-box;
        padding: 0 40px;
      }
      .qr{
        box-sizing: border-box;
        padding: 0 40px;
        display: flex;
        align-items: center;
        img{
          width: 90px;
          height: 90px;
        }
        dl{
          margin-left: 20px;
          dt{
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }
          dd{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
  }

}
/deep/ .el-dialog__header{
  display: none;
}
</style>