import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    consultDialog: false
  },
  mutations: {
    dialogHandle(state,val){
      state.consultDialog = val
    }
  },
  getters:{
    consultDialog: state => state.consultDialog
  },
  actions: {
  },
  modules: {
  }
})

