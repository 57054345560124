<template>
  <div class='moreProd'>
    <h3>更多产品与服务</h3>
    <ul>
        <li @click="$router.push('/cdn')" >
            <img v-lazy="require('../../../assets/images/cloud/img_zhidunshen.png')" alt="">
            <div>
                <h4>安全CDN防护系统</h4>
                <p>实时CC防护、Web应用免遭网络、应用、业务等各种已知和未知威胁。</p>
            </div>
        </li>
        <li @click="$router.push('/clean')">
            <img v-lazy="require('../../../assets/images/cloud/img_Dwangjing.png')" alt="">
            <div>
                <h4>内容安全监测系统</h4>
                <p>提供更全面的词库防护，实时监测系统页面内容，拦截、检测、自动替换文字，高效助力网络净化。</p>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.moreProd{
    height: 453px;
    >h3{
        margin-top: 50px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 42px;
        text-align: center;
    }
    >ul{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 90px;
        li{
            width: 580px;
            height: 161px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #EDEDED;
            box-sizing: border-box;
            padding: 28px 40px;
            display: flex;
            align-content: center;
            position: relative;
            top: 0;
            transition: all 0.5s;
            cursor: pointer;
            >div{
                margin-left: 39px;
                h4{
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 25px;
                }
                p{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                    margin-top: 10px;
                }
            }
            img{
                width: 105px;
                width: 105px;
                object-fit: contain;
            }
        }
        li:hover{
        top: -5px;
        box-shadow: 2px 2px 15px rgb(225,225,225);
        }
    }
}
</style>