<template>
  <div class='rescue'>
    <detail-banner :bgUrl="'heike'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="rescue-serve">
        <h3>具体事件</h3>
        <ul>
            <li>
                <img src="../../assets/images/cloud/icon_kongzhi.png" alt="">
                <dd>控制服务器</dd>
                <dt>业务系统、服务器发现异常登录，权限被控制无法重新获取高权限。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_bingdu.png" alt="">
                <dd>病毒／木马／后门事件</dd>
                <dt>服务器甚至整个业务系统被植入病毒、木马或被上传恶意文件、恶意入侵。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_shuju.png" alt="">
                <dd>数据泄漏事件</dd>
                <dt>业务系统、服务器资源数据泄露或被恶意篡改数据。</dt>
            </li>

        </ul>
    </div>

    <div class="rescue-method">
        <div class="rescue-method-serve">
            <h3>服务方式</h3>
            <ul>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/icon_yingji.png')" alt="">
                    <dd>应急响应技术支持</dd>
                    <dt>当客户出现紧急安全事件时，通过遏制、根除、恢复、跟踪四个阶段支持快速、高效的从攻击事件中恢复。</dt>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/icon_quanmian.png')" alt="">
                    <dd>全面安全渗透测试</dd>
                    <dt>安全专家团队对目标网络、业务系统、数据库进行漏洞挖掘，对发现可导致企业数据泄露、资产受损、数据被篡改等漏洞进行全面渗透测试。</dt>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/icon_jiankong.png')" alt="">
                    <dd>敏感时期监控服务</dd>
                    <dt>对于特殊时期、 两会、国庆等敏感时期，系统和功能上线、促销和商业活动等重要时期提供远程或驻场安全运维监控，防止黑客攻击。</dt>
                </li>

            </ul>
        </div>
    </div>
    <more-prod></more-prod>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '黑客入侵救援',
        tips: '当客户出现紧急安全事件时，帮助客户解决服务器被控制、木马等风险，高效的从攻击事件中恢复正常。'
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.rescue{
    width: 100%;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 488px;
        text-align: center;
        >h3{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            margin-top: 50px;
        }
        >ul{
            display: flex;
            justify-content: space-between;
            margin-top: 94px;
            >li{
                width: 303px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                >img{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                }
                dd{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin: 24px 0 14px 0;
                }
            }
        }
    }
    &-method{
        height: 468px;
        background: #F7F8FB;
        overflow: hidden;
        &-serve{
            width: 1200px;
            margin: 0 auto;
            height: 448px;
            text-align: center;
            >h3{
                height: 42px;
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 42px;
                margin-top: 50px;
            }
            >ul{
                display: flex;
                justify-content: space-between;
                margin-top: 90px;
                >li{
                    width: 303px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                    >img{
                        width: 54px;
                        height: 54px;
                        object-fit: contain;
                    }
                    dd{
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        line-height: 28px;
                        margin: 24px 0 14px 0;
                    }
                }
            }
        }
    }
}
</style>