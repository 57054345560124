<template>
    <div class='train'>
        <detail-banner :bgUrl="'DClear'" :tit="tit" :tips="tips" ></detail-banner>
         <div class="TextGroup5">
            <div class="">
                <span class="word5">内容监测管控解决的问题</span>
            </div>
            <div class="flex-row ">
                <div class="flex-col">
                <img src="../../assets/images/cloud/DClean/icon_jiance1.png" alt="" srcset="">
                <div class="section ">
                    <span class="info5"
                    >因国家相关政策要求网络内容安全整治，导致文本、图片违规带来的巨额罚款。</span
                    >
                </div>
                </div>
                <div class="flex-col">
                <img src="../../assets/images/cloud/DClean/icon_jiance2.png" alt="" srcset="">
                <div class="section ">
                    <span class="word8"
                    >因平台创作内容、文章中违禁词及违禁舆论违规带来的相关风险。</span
                    >
                </div>
                </div>
                <div class="flex-col">
                <img src="../../assets/images/cloud/DClean/icon_jiance3.png" alt="" srcset="">
                <div class="section ">
                    <span class="txt7"
                    >因宣传内容、平台内容违规带来的关停、下架。</span
                    >
                </div>
                </div>
                <div class="flex-col">
                <img src="../../assets/images/cloud/DClean/icon_jiance4.png" alt="" srcset="">
                <div class="section ">
                    <span class="info7">人工审核对变字、变词识别率差，容易照成页面盲区，对实时信息获取存在滞后。</span
                    >
                </div>
                </div>
            </div>
        </div>
        <div class="feature">
            <h3>产品特性</h3>
            <ul>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/DClean/icon_shehuang.png')" alt="">
                    <dl>
                        <dt>涉黄赌毒检测</dt>
                        <dd>精准识别色情文本，能识别各种涉黄赌毒敏感关键词，智能学习分析模型，具有较高抗干扰能力。</dd>
                    </dl>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/DClean/icon_shezheng.png')" alt="">
                    <dl>
                        <dt>涉政检测</dt>
                        <dd>精准识别涉政关键词，例：高丽、蛮子、回回等，有效减少网络群众敏感舆论。</dd>
                    </dl>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/DClean/icon_shehei.png')" alt="">
                    <dl>
                        <dt>涉暴恐检测</dt>
                        <dd>识别黑火药、雷管、炸药、宣扬黑势力等涉黑涉暴恐内容，精准识别，高效助力网络净化。</dd>
                    </dl>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/DClean/icon_zidingyi.png')" alt="">
                    <dl>
                        <dt>涉反动检测</dt>
                        <dd>依托海量数据搭建的多种语义模型，可有效识别反动文本，帮助企业显著减少人工审核工作量</dd>
                    </dl>
                </li>
                <li>
                    <img v-lazy="require('../../assets/images/cloud/DClean/icon_zidingyi.png')" alt="">
                    <dl>
                        <dt>自定义检测</dt>
                        <dd>用户可通过自定义关键词库，识别包含自定义类型的关键词内容，满足不同场景的运营需求。</dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
export default {
  components: {
    detailBanner
  },
  data() {
    return {
        tit: '内容安全监测系统',
        tips: '配备各大词库数据库，提供更全面的词库防护，精准识别文字，多策略保护网站内容，可做到拦截、检测、自动替换文字，高效助力网络净化。',
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.train{
    width: 100%;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 488px;
        
    }
    .TextGroup5 {
      width: 100%;
      .flex-row{
        width: 1200px;
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .flex-col{
            width: 279px;
            height: 300px;
            background: #FFFFFF;
            box-sizing: border-box;
            padding: 25px 36px;
            text-align: center;
            border: 1px solid #F1F1F1;
          img{
            width: 82px;
            margin: 0 auto;
            margin-top: 20px;
            height: 82px;
          }
          .section{
            width: 230px;
            margin: 0 auto;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            
            
          }
          span{
            margin-top: 40px;
            display: inline-block;
            width: 230px;
            height: 78px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            text-align: left;
          
          }
        }
        
      }
      // margin: 50px 0 0 810px;
      .word5 {
        margin-top: 50px;
        text-align: center;
        height: 42px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 42px;
        display: block;
      }
      .word6 {
        text-align: center;
        height: 21px;
        overflow-wrap: break-word;
        color: rgba(155, 158, 165, 1);
        font-size: 15px;
        white-space: nowrap;
        line-height: 21px;
        margin-top: 16px;
        display: block;
      }
    }
    .feature{
        margin-top: 50px;
        margin-bottom: 40px;
        h3{
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            text-align: center;
        }
        ul{
            width: 1200px;
            margin:  0 auto;
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                width: 586px;
                height: 160px;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px solid #F1F1F1;
                box-sizing: border-box;
                padding: 36px 36px 30px 50px ;
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width: 50px;
                    height: 50px;
                }
                dt{
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 25px;
                }
                dd{

                    width: 428px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>