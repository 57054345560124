<template>
  <div class='infiltrate'>
    <detail-banner :bgUrl="'heike'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="infiltrate-flow">
        <div class="process">
            <img src="../../assets/images/cloud/speedServe/img_fuwujiesao.png" alt="">
            <div class="right">
                <p>service introduction</p>
                <dl>
                    <dt>服务介绍</dt>
                    <dd>对国内主要漏洞发布平台进行监控，发现正对客户业务应用系统的漏洞信息，在第一时间通知客户，进行预警。</dd>
                    <dd>针对发布平台提供的漏洞信息，通过提供详细的日志分析，并结合人工安全服务，进行漏洞确认及保护效果确认。同时根据客户需求，进行有效的产品配置和调整。</dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="infiltrate-serve">
        <h3>我们的优势</h3>
        <ul>
            <li>
                <img src="../../assets/images/cloud/speedServe/icon_yingji.png" alt="">
                <dd>响应快</dd>
                <dt>当主要漏洞发布平台发布新的漏洞，第一时间对客户业务应用系统进行审查。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/speedServe/icon_quanmian.png" alt="">
                <dd>及时预警</dd>
                <dt>发现漏洞可能对客户业务应用系统带来危害，第一时间对客户进行预警并提供专业方案。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/speedServe/icon_jiankong.png" alt="">
                <dd>专家团队</dd>
                <dt>根据客户需求东智信安专家安全服务团队竭诚为客户带来专业服务体验。</dt>
            </li>

        </ul>
    </div>
    <div style="backgroundColor:#fff;paddingTop:50px">
        <more-prod></more-prod>
    </div>
    
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '漏洞速递服务',
        tips: '对国内主要漏洞发布平台进行监控，发现正对客户业务应用系统的漏洞信息，在第一时间通知客户，进行预警。'
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitHandle(){
        this.$store.commit('dialogHandle',true)
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.infiltrate{
    width: 100%;
    background: #f7f8fb;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        // height: 514px;
        margin-bottom: 70px;
        text-align: center;
        
        >h3{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            margin-top: 70px;
        }
        >ul{
            display: flex;
            justify-content: space-between;
            margin-top: 69px;
            >li{
                width: 303px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                >img{
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
                dd{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin: 20px 0 14px 0;
                }
            }
        }
    }
    &-flow{
        background: #F7F8FB;
        overflow: hidden;
        .process{
            width: 1200px;
            margin: 0 auto;
            width: 1200px;
            height: 362px;
            background: #FFFFFF;
            border-radius: 6px;
            margin-top: 60px;
                display: flex;
                img{
                    width: 224px;
                    height: 225px;
                    margin-top: 72px;
                    margin-left: 124px;
                }
                .right{
                    max-width: 619px;
                    margin-top: 55px;
                    margin-left: 110px;
                    p{
                        font-size: 30px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #e5e5e5;
                        line-height: 42px;
                    }
                    dl{
                        
                        dt{
                            font-size: 30px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            line-height: 42px;
                            margin-bottom: 44px;
                            position: relative;
                        }
                        dt::after{
                            content: '';
                            position: absolute;
                            display: inline-block;
                            top:64px;
                            left: 0;
                            height: 3px;
                            width: 60px;
                            background: #2149DD;
                        }
                        dd{
                            padding-left: 10px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 26px;
                            position: relative;
                        }
                        dd::after{
                            content: '';
                            position: absolute;
                            display: inline-block;
                            top:10px;
                            left: 0;
                            width: 4px;
                            height:4px;
                            border-radius: 50%;
                            background: #050505;
                        }
                    }
                }
        }
    }
}
</style>