<template>
  <div class='train'>
        <detail-banner :bgUrl="'cdn'" :tit="tit" :tips="tips" ></detail-banner>
        <div class="mod4 flex-col">
            <h3 class="tit">安全、稳定的产品</h3>
            <p class="tip">提供安全加速、业务安全、等保服务等一站式安全服务，为您的网站安全保驾护航</p>
            <div class="outer2 flex-row justify-between">
                <div class="block1 flex-row">
                    <div
                        class="box4 flex-col"
                        v-for="(item, index) in loopData0"
                        :style="{ backgroundImage: 'url('+item.lanhuBg2Far+')' }"
                        :key="index"
                    >
                        <div class="group2 flex-col justify-between">
                        <div
                            :class="['group3', 'groupInfo'+index ]"
                            :style="{ backgroundImage: 'url('+item.lanhuBg2+')'}"
                        >
                        </div>
                        <div class="TextGroup3 flex-col justify-between">
                            <span
                            class="word3"
                            >{{item.lanhutext0}}
                            </span>
                            <span
                            class="word4"
                            >
                            {{item.lanhutext1}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="train-my"
      v-lazy:background-image="require('../../assets/images/cloud/cdn/img_jiejuefangan.png')"
    >
        <h3>行业解决方案</h3>
        <div class="train-my-content">
            <ul>
                <li @click="showFlag = !showFlag" :class="!showFlag ? 'click' : '' " >公有云部署</li>
                <li @click="showFlag = !showFlag" :class="showFlag ? 'click' : '' ">私有云部署</li>
            </ul>
            <i></i>
            <dl v-show="showFlag">
                <dt>私有云部署</dt>
                <dd>
                    东智信安可以为某些超大规模组织或行业用户定制开发并部署整套CDN管理系统，使用户完全以私有化的方式得到CDN管理系统的整套功能，并拥有最终的管理权、运营权。
                    对于拥有成千上万的超大型组织、行业或者监管机构，可以自主部署整套CDN管理系统，最终以相对较低的成本为众多网站提供安全服务。
                </dd>
                <img  v-lazy="require('../../assets/images/cloud/img_siyouyun.png')" alt="">
            </dl>
            <dl v-show="!showFlag">
                <dt>公有云部署</dt>
                <dd>
                    无需部署任何设备、无需任何网络改动、无需任何服务器配置改动，只需简单的修改DNS指向即可，2分钟完成部署，后续安全维护由东智信安专家团队完成，用户无需参与，真正做到防护“零部署，零维护”。
                </dd>
                <img class="com" v-lazy="require('../../assets/images/cloud/cdn/img_gongyouyun.png')" alt="">
            </dl>
        </div>
    </div>
    <div class="TextGroup5">
      <div class="">
        <span class="word5">专业服务</span>
        <span class="word6">针对网站防护，提供专业工具，保护业务安全</span>
      </div>
      <div class="flex-row ">
        <div class="flex-col">
          <img v-lazy="require('../../assets/images/cloud/icon_wangzhanfanghu.png')" alt="" srcset="">
          <div class="section ">
            <h4 class="txt6">网站防护</h4>
            <span class="info5"
              >为客户提域名接入、功能配置，CC攻击主动做出策略防护等安 全防护功能。</span
            >
          </div>
        </div>
        <div class="flex-col">
          <img v-lazy="require('../../assets/images/cloud/icon_rizhifenxi.png')" alt="" srcset="">
          <div class="section ">
            <h4 class="word7">日志分析服务</h4>
            <span class="word8"
              >日志分析包括:访客、IP、请求情况分析、CC防护分析、状态码等。</span
            >
          </div>
        </div>
        <div class="flex-col">
          <img v-lazy="require('../../assets/images/cloud/icon_.png')" alt="" srcset="">
          <div class="section ">
            <h4 class="word9">源站减负</h4>
            <span class="txt7"
              >通过CC防护网络，防护CC攻击，当遇到大量攻击，系统自动分配主机分摊压力，降低源站负载。</span
            >
          </div>
        </div>
        <div class="flex-col">
          <img v-lazy="require('../../assets/images/cloud/icon_yuanzhanbaohu.png')" alt="" srcset="">
          <div class="section ">
            <h4 class="info6">源站保护</h4>
            <span class="info7"
              >通过反向代理接入防护服务，隐藏真实源站服务器地址。</span
            >
          </div>
        </div>
      </div>
        
    </div>
    <div class="threeModu">
        <h3>三大模块介绍</h3>
        <p>三大模块提供全面保护，让业务更稳定，更无忧</p>
        <div class="threeModu-des">
            <dl>
                <dt>CC防护引擎</dt>
                <dd>基于CC人机识别、CC基础引擎两大模块配置，针对受到CC攻击时，利用流量攻击清洗机制，进行高效防御。系统实时监控，主动防御并生成攻击日志，统计攻击来源与攻击目标。</dd>
            </dl>
            <img v-lazy="require('../../assets/images/cloud/img_ccfanghu.png')" alt="">
        </div>
    </div>
    <div class="threeModu moduTwo ">
        <div class="threeModu-des">
            <img v-lazy="require('../../assets/images/cloud/cdn/img_zhidunshen.png')" alt="">
            <dl>
                <dt>智盾神（Web应用防火墙）</dt>
                <dd>基于自定义规则引擎可以灵活定制防护，可生成数百种策略级纬度的访问控制防护规则，满足业务的定制防护需求。实时保护Web应用免遭网络、应用、业务等各种已知和未知威胁。</dd>
            </dl>
            
        </div>
    </div>
    <div class="threeModu moduLast ">
        <div class="threeModu-des">
            <dl>
                <dt>DNS智能解析调度</dt>
                <dd>安全可靠的智能DNS解析服务，领先的高性能架构，拥有安全防御能力、海量请求处理能力，支持全球及自定义智能解析线路，提供安全、稳定、可靠的解析体验。通过CC防护网络，防护CC攻击，当遇到大量攻击，系统智能调度资源主机分摊压力，降低源站负载，确保网站健康运行。</dd>
            </dl>
            <img v-lazy="require('../../assets/images/cloud/cdn/img_dns.png')" alt="">
        </div>
    </div>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
export default {
  components: {
    detailBanner
  },
  data() {
    return {
        tit: '安全CDN管理系统',
        tips: '基于域名管理、资源管理、功能配置三大功能模块，对成功接入系统的域名设置多维度的防护配置，一整套完整的功能，可对用户网站做到精确防护，保障网站安全，为业务保驾护航。',
        loopData0: [
            {
            lanhuBg2Far:require('../../assets/images/cloud/cdn/home_icon_yumingguanli_bg.png'),
            lanhuBg2:require('../../assets/images/cloud/cdn/home_icon_yumingguanli_n.png'),
            lanhuBg2Hover:require('../../assets/images/cloud/cdn/home_icon_yumingguanli_h.png'),
            lanhutext0: '域名管理',
            lanhutext1: '主要针对客户域名及SLL证书管理，你可以在系统接入域名后可对域名进行回源、上云、绑定SSL证书的操作。'
            },
            {
            lanhuBg2Far:require('../../assets/images/cloud/cdn/home_icon_ziyuanguanli_bg.png'),
            lanhuBg2: require('../../assets/images/cloud/cdn/home_icon_ziyuanguanli_n.png'),
            lanhutext0: '资源管理',
            lanhutext1:'对CDN分组及节点进行管理，你可以在资源管理模块中对域名与主机进行关联，当域名受到CC攻击时，为缓解主机压力，系统将自动将流量转移到其他主机。'
            },
            {
            lanhuBg2Far:require('../../assets/images/cloud/cdn/home_icon_gongnengpeizhi_bg.png'),
            lanhuBg2: require('../../assets/images/cloud/cdn/home_icon_gongnengpeizhi_n.png'),
            lanhutext0: '功能配置',
            lanhutext1: '在控制台通过进入配置页面对域名进行五个维度的配置，可针对CC防护、WAF防护、地域访问控制、自定义访问控制等精准防护配置。'
            }
        ],
        showFlag:false
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.train{
    width: 100%;
    background-color: #F7F8FB;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 488px;
        
    }
    &-my{
        height: 660px;
        background: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        h3{
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 42px;
            text-align: center;
            margin-top: 50px;
        }
        &-content{
            width: 1200px;
            margin: 0 auto;
            margin-top: 60px;
            color: #fff;
            display: flex;
            >ul{
                
                li{
                    width: 70px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    margin-bottom: 30px;
                    cursor: pointer;
                }
                .click{
                    color: #2149DD;
                }
            }
            >i{
                display: inline-block;
                width: 1px;
                height: 420px;
                background: #FFFFFF;
                margin: 0 80px 0 60px;
            }
            dl{
                dt{
                    height: 33px;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 33px;
                }
                dd{
                    width: 920px;
                    height: 104px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 26px;
                    margin-top: 20px;
                }
                img{
                    margin-top: 40px;
                    width: 664px;
                    height: 216px;
                    object-fit: contain;
                }
                .com{
                    width: 522px;
                    height: 100px;
                }
            }
        }   
    
    }
    .mod4 {
        width: 100%;
        height: 432px;
        background-color: #fff;
        margin-top: -12px;
        width: 100%;
        overflow: hidden;
        padding-bottom: 100px;
        .tit{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            text-align: center;
            margin-top: 50px;
        }
        .tip{
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9EA5;
            line-height: 21px;
            text-align: center;
            margin-top: 16px;
        }
        .outer2 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .block1 {
            display: flex;
            width: 1200px;
            height: 280px;
            justify-content: space-between;
            .box4 {
              height: 280px;
              margin-right: 45px;
              width: 370px;
              background-position: 100% -10px;
              background-repeat: no-repeat;
              // background-size: 50%;
              
                border: 1px solid #F1F1F1;
              border-radius: 4px;
              .group2 {
                width: 370px;
                height: 280px;
                // margin: 49px 0 0 40px;
                box-sizing: border-box;
                padding: 40px;
                color: #000000;
                .group3 {
                  width: 30px;
                  height: 30px;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                }
                .TextGroup3 {
                  height: 118px;
                  margin-top: 26px;
                  width: 303px;
                  .word3 {
                    width: 72px;
                    height: 25px;
                    overflow-wrap: break-word;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 25px;
                    display: block;
                    color: #000000;
                  }
                  .word4 {
                    width: 303px;
                    overflow-wrap: break-word;
                    font-size: 14px;
                    text-align: left;
                    line-height: 26px;
                    display: inline-block;
                    margin-top: 15px;
                    color: #666666;
                  }
                }
              }
            }
            .box4:hover{
              background-color: #2b48d5;
              transition: 1s;
              cursor: pointer;
              .groupInfo0{
                background-image: url('../../assets/images/cloud/cdn/home_icon_yumingguanli_h.png') !important;
              }
              .groupInfo1{
                background-image: url('../../assets/images/cloud/cdn/home_icon_ziyuanguanli_h.png')!important;
              }
              .groupInfo2{
                background-image: url('../../assets/images/cloud/cdn/home_icon_gongnengpeizhi_h.png')!important;
              }
              .group3{
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
              }
              .TextGroup3{
                .word3{
                  color: #FFFFFF;
                }
                .word4{
                  color: #FFFFFF;
                }
              }
              
            }
          }
        }
    }
    .TextGroup5 {
    
      height: 553px;
      width: 100%;
      .flex-row{
        width: 1200px;
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .flex-col{
            height: 340px;
            background: #FFFFFF;
            border: 1px solid #F1F1F1;
            box-sizing: border-box;
            padding: 25px;
            text-align: center;
          img{
            width: 82px;
            margin: 0 auto;
            margin-top: 27px;
            height: 82px;
          }
          .section{
            width: 230px;
            margin: 0 auto;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            
            
          }
          h4{
            width: 100%;
            margin-top: 30px;
            text-align: center;
            height: 25px;
            color: rgba(0, 0, 0, 1);
            font-size: 18px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            white-space: nowrap;
            line-height: 25px;
            display: block;


          }
          span{
            margin-top: 20px;
            display: inline-block;
            width: 230px;
            height: 78px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            text-align: left;
          
          }
        }
        
      }
      // margin: 50px 0 0 810px;
      .word5 {
        margin-top: 50px;
        text-align: center;
        height: 42px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 42px;
        display: block;
      }
      .word6 {
        text-align: center;
        height: 21px;
        overflow-wrap: break-word;
        color: rgba(155, 158, 165, 1);
        font-size: 15px;
        white-space: nowrap;
        line-height: 21px;
        margin-top: 16px;
        display: block;
      }
    }
    .threeModu{
        background-color: #fff;
        overflow: hidden;
        height: 520px;
        h3{
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            text-align: center;
            margin-top: 50px;
        }
        p{
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9EA5;
            line-height: 21px;
            margin-top: 16px;
            text-align: center;
        }
        &-des{
            width: 1200px;
            margin: 0 auto;
            margin-top: 90px;
            margin-bottom: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            dl{
                width: 565px;
                dt{
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 33px;
                }
                dd{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                    margin-top: 22px;
                }
            }
            img{
                width: 550px;
                height: 229px;
            }
        }
    }
    .moduTwo{
        background: #F7F8FB;
        height: 400px;
        img{
            width: 566px;
            height: 250px
        }
    }
    .moduLast{
        height: 400px;
        img{
            width: 543px;
            height: 270px;
        }
    }
}
</style>