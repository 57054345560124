import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Layout from '../views/layout/index.vue'
import Infiltrate from '../views/cloudSecu/infiltrate.vue'
import Rescue from '../views/cloudSecu/rescue.vue'
import RiskAssess from '../views/cloudSecu/riskAssess.vue'
import InforDrill from '../views/cloudSecu/inforDrill.vue'
import Train from '../views/cloudSecu/train.vue'
import CDN from '../views/cloudSecu/cdn.vue'
import DClean from '../views/cloudSecu/DClean.vue'
import FastServe from '../views/cloudSecu/fastServe.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: Home
      }
    ]
  },
  {
    path: '/company',
    name: 'Company',
    redirect: '/company',
    component: Layout,
    children: [
      {
        path: '/',
        component: About
      }
    ]
  },
  {
    path: '/infiltrate',
    name: 'Infiltrate',
    redirect: '/Infiltrate',
    component: Layout,
    children: [
      {
        path: '/',
        component: Infiltrate
      }
    ]
  },
  {
    path: '/rescue',
    name: 'rescue',
    redirect: '/rescue',
    component: Layout,
    children: [
      {
        path: '/',
        component: Rescue
      }
    ]
  },
  
  {
    path: '/riskAssess',
    name: 'riskAssess',
    redirect: '/riskAssess',
    component: Layout,
    children: [
      {
        path: '/',
        component: RiskAssess
      }
    ]
  },
  {
    path: '/inforDrill',
    name: 'inforDrill',
    redirect: '/inforDrill',
    component: Layout,
    children: [
      {
        path: '/',
        component: InforDrill
      }
    ]
  },
  {
    path: '/train',
    name: 'train',
    redirect: '/train',
    component: Layout,
    children: [
      {
        path: '/',
        component: Train
      }
    ]
  },
  {
    path: '/cdn',
    name: 'cdn',
    redirect: '/cdn',
    component: Layout,
    children: [
      {
        path: '/',
        component: CDN
      }
    ]
  },
  {
    path: '/clean',
    name: 'clean',
    redirect: '/cdcleann',
    component: Layout,
    children: [
      {
        path: '/',
        component: DClean
      }
    ]
  },
  
  {
    path: '/vulService',
    name: 'vulService',
    redirect: '/vulService',
    component: Layout,
    children: [
      {
        path: '/',
        component: FastServe
      }
    ]
  },
  
  {
    path: '/join',
    name: 'join',
    redirect: '/join',
    component: Layout,
    children: [
      {
        path: '/',
        component: ()=>import('@/views/about/joinUs.vue')
      }
    ]
  }
  
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
