<template>
  <div class='infiltrate'>
    <detail-banner :bgUrl="'shentou'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="infiltrate-serve">
        <h3>服务内容</h3>
        <ul>
            <li>
                <img src="../../assets/images/cloud/icon_loudong.png" alt="">
                <dd>漏洞挖掘</dd>
                <dt>安全应用检测是通过人工及工具相结合的方式挖掘渗透应用中存在的安全漏洞并进行验证。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_xiufu.png" alt="">
                <dd>漏洞修复方案</dd>
                <dt>安全专家团队根据渗透结果对针对渗透目标撰写出渗透测试报告，分析漏洞并提出漏洞修复方案及建议。</dt>
            </li>
            <li>
                <img src="../../assets/images/cloud/icon_huigui.png" alt="">
                <dd>回归测试</dd>
                <dt>漏洞修复完成后，对修复结果进行有效性验证及评估，核验漏洞的修复结果，更新并发送回归测试报告。</dt>
            </li>

        </ul>
    </div>

    <div class="infiltrate-flow">
        <div class="process">
            <h3>服务流程</h3>
            <div class="process-box">
                <ul>
                    <li>
                        <div class="step" >
                            <i>1</i>
                            <div>
                                <h4>预约</h4>
                                <span>make an appointment</span>
                            </div>
                        </div>
                        <div class="tip" >
                            <i class="el-icon-check"></i>
                            <span>确定范围</span>
                        </div>
                        <p>通过对测试范围进行精确分析，从而制定出有针对性地模拟攻击计划</p>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>商务沟通</span>
                        </div>
                        <p>确定测试意向，签订合同</p>
                    </li>
                    <li>
                        <div class="step">
                            <i>2</i>
                            <div>
                                <h4>提交</h4>
                                <span>make an appointment</span>
                            </div>
                        </div>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>提交材料</span>
                        </div>
                        <p>一般包括系统账号，稳定的测试环境，业务流程等</p>
                    </li>
                    <li>
                        <div class="step">
                            <i>3</i>
                            <div>
                                <h4>执行</h4>
                                <span>implement</span>
                            </div>
                        </div>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>风险分析</span>
                        </div>
                        <p>熟悉应用，进行风险分析，设计测试风险点</p>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>测试执行</span>
                        </div>
                        <p>安全测试专家分组进行安全渗透测试，提交漏洞</p>
                    </li>
                    <li>
                        <div class="step">
                            <i>4</i>
                            <div>
                                <h4>完成</h4>
                                <span>complete</span>
                            </div>
                        </div>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>漏洞修复</span>
                        </div>
                        <p>企业按照测试报告进行修复</p>
                        <div class="tip">
                            <i class="el-icon-check"></i>
                            <span>回归测试</span>
                        </div>
                        <p>跟进回归测试，验证漏洞修复情况</p>
                    </li>
                </ul>
                <div class="btnBox">
                    <el-button class="btn" @click="submitHandle" type="primary">立即咨询</el-button>
                </div>
            </div>
        </div>
    </div>
    <more-prod></more-prod>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '渗透测试',
        tips: '利用主流的攻击技术和工具对目标网络、业务系统、数据库进行模拟黑客攻击测试，对发现可导致企业数据泄露、资产受损、数据被篡改等漏洞，输出针对性的修复方案。'
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitHandle(){
        this.$store.commit('dialogHandle',true)
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.infiltrate{
    width: 100%;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        // height: 514px;
        margin-bottom: 70px;
        text-align: center;
        >h3{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            margin-top: 50px;
        }
        >ul{
            display: flex;
            justify-content: space-between;
            margin-top: 69px;
            >li{
                width: 303px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                >img{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                }
                dd{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                    margin: 20px 0 14px 0;
                }
            }
        }
    }
    &-flow{
        height: 658px;
        background: #F7F8FB;
        overflow: hidden;
        .process{
            width: 1200px;
            margin: 0 auto;
            // text-align: center;
            >h3{
                margin: 50px 0;
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                text-align: center;
            }
            &-box{
                height: 456px;
                background: #FFFFFF;
                box-shadow: 0px 4px 8px 0px rgba(61,62,65,0.0900);
                border-radius: 6px;
                >ul{
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 40px 30px;
                    li{
                        width: 240px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 24px;
                        .step{
                            width: 250px;
                            height: 70px;
                            background: #F7F8FB;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            
                            >i{
                                display: inline-block;
                                width: 20px;
                                height: 47px;
                                font-size: 40px;
                                font-family: DINAlternate-Bold, DINAlternate;
                                font-weight: bold;
                                color: #2149DD;
                                line-height: 47px;
                                margin: 0 11px 0 18px;
                            }
                            >div{
                                height: 42px;
                                h4{
                                    font-size: 18px;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #000000;
                                    margin-bottom: -6px;
                                    margin-top: 4px;
                                }
                                span{
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #e1e2e5;
                                    margin: 0;
                                }
                            }
                        }
                        .tip{
                            margin-top: 30px;
                            >i{
                                font-size: 16px;
                                color: #2149DD;
                                font-weight: 600;
                            }
                            >span{
                                margin-left: 6px;
                                font-size: 14px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #333333;
                                line-height: 20px;
                            }
                        }
                        p{
                            margin-top: 4px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 24px;
                        }
                    }
                }
                .btnBox{
                    
                    display: flex;
                    justify-content: center;
                }
                .btn{
                    width: 170px;
                    background-color: #2149DD;
                    border: #2149DD;
                }
            }
        }
    }
}
</style>