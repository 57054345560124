<template>
  <div class='inforDrill'>
    <detail-banner :bgUrl="'inforDrill'" :tit="tit" :tips="tips" ></detail-banner>
    <div class="inforDrill-serve">
        <h3>信息安全对抗演习的必要性</h3>
        <ul>
            <li>
                <img src="../../assets/images/cloud/img_qiyeanquan.png" alt="">
                <div>
                    <h4>增强企业员工安全知识</h4>
                    <p>全面增强企业员工安全意识及安全知识，组织有效培训。</p>
                </div>
            </li>
            <li>
                <img src="../../assets/images/cloud/img_xuexi.png" alt="">
                <div>
                    <h4>提高威胁应对能力</h4>
                    <p>通过组织企业安全对抗演习，提高企业安全风险威胁来临时的应对能力。</p>
                </div>
            </li>
        </ul>
    </div>

    <div class="inforDrill-method">
        <div class="inforDrill-method-serve">
            <h3>服务综述</h3>
            <div class="box">
                <ul class="top">
                    <li>准备阶段</li>
                    <li>对抗演习阶段</li>
                    <li>复盘阶段</li>
                </ul>
                <ul class="content">
                    <li>
                        <div>搭建仿真环境</div>
                        <div>明确评估时间</div>
                        <div>制定应急策略</div>
                        <div>明确评估范围</div>
                        <div>资产梳理</div>
                        <div>定制演练方案</div>
                        <div>风险评估</div>
                        <div>安全培训</div>
                    </li>
                    <li>
                        <div>内网权限驻留</div>
                        <div>关键资产发现</div>
                        <div>痕迹隐藏</div>
                        <div>定向网络攻击</div>
                        <div>物理手段攻击</div>
                        <div>目标数据获取</div>
                        <div>社交手段攻击</div>
                        <div>近源渗透攻击</div>
                    </li>
                    <li>
                        <div>演练总结</div>
                        <div>漏洞修复建议</div>
                        <div>安全事件实时监测</div>
                        <div>安全建设方案</div>
                    </li>
                </ul>
            </div>
            <div class="btnBox">
                <el-button class="btn" @click="submitHandle" type="primary">立即咨询</el-button>
            </div>
        </div>
    </div>
    <more-prod></more-prod>
  </div>
</template>

<script>
import detailBanner from '@/components/detailBanner.vue'
import moreProd  from './component/moreProd'
export default {
  components: {
    detailBanner,
    moreProd
  },
  data() {
    return {
        tit: '信息安全对抗演习',
        tips: '针对提高企业员工安全意识和安全团队技术能力，促进政府、央企及企业的信息安全团队的交流、学习，发现系统薄弱点。'
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitHandle(){
        this.$store.commit('dialogHandle',true)
    }
  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.inforDrill{
    width: 100%;
    &-serve{
        width: 1200px;
        margin: 0 auto;
        height: 410px;
        
        >h3{
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            margin-top: 50px;
            text-align: center;
        }
        >ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            margin-top: 60px;
            li{
                width: 580px;
                // height: 200px;
                background: #FFFFFF;
                border-radius: 6px;
                box-sizing: border-box;
                padding: 28px 40px;
                display: flex;
                align-items: center;
                >div{
                    margin-left: 39px;
                    h4{
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                        line-height: 28px;
                    }
                    p{
                        margin-top: 10px;
                        width: 303px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 26px;
                    }
                }
                img{
                    width: 200px;
                    width: 200px;
                    object-fit: contain;
                }
            }
        }
    }
    &-method{
        background: #F7F8FB;
        overflow: hidden;
        &-serve{
            width: 1200px;
            margin: 0 auto;
            
            >h3{
                height: 42px;
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 42px;
                margin-top: 50px;
                text-align: center;
            }
            .box{
                margin-top: 80px;
                width: 1200px;
                height: 470px;
                background: #FFFFFF;
                border-radius: 6px;
                box-sizing: border-box;
                padding: 20px;
                .top{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    li:nth-child(1){
                        width: 421px;
                        background-image: url('../../assets/images/cloud/img_yi.png');
                    }
                    li:nth-child(2){
                        width: 460px;
                        background-image: url('../../assets/images/cloud/img_er.png');
                    }
                    li:nth-child(3){
                        width: 277px;
                        background-image: url('../../assets/images/cloud/img_san.png');
                    }
                    li{
                        height: 60px;
                        background-position: center center;
                        // background-repeat: no-repeat;
                        background-size: cover;
                        color: #fff;
                        text-align: center;
                        line-height: 60px;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                    }
                }
                .content{
                    display: flex;
                    justify-content: space-between;
                    li{
                        justify-content: space-between;
                        flex-wrap: wrap;
                        >div{
                            
                            height: 80px;
                            background: #F7F8FB;
                            text-align: center;
                            line-height: 80px;
                            margin-top: 10px;
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #131415;
                        }
                    }
                    li:nth-child(1){
                        display: flex;
                        width: 422px;
                        margin-right: 20px;
                        >div{
                            width: 200px;
                        }
                    }
                    li:nth-child(2){
                        display: flex;
                        width: 460px;
                        margin-right: 20px;
                        >div{
                            width: 217px;
                        }
                    }
                    li:nth-child(3){
                        >div{
                            width: 263px;
                        }
                        width: 277px;
                    }
                }
            }
            .btnBox{
                margin: 70px 0;
                display: flex;
                justify-content: center;
                .btn{
                    width: 170px;
                    background-color: #2149DD;
                    border: #2149DD;
                }
            }
                
        }
    }
}
</style>